import style from './Background.module.scss'

const Background = () => {
  return (
    <div className={style.backCont}>
        <img src="/assets/background/back1.png" alt="" />
    </div>
  )
}

export default Background