import React from 'react';
import styles from './GeneralCard.module.scss';
import Viewer from 'react-viewer';

interface TypeGeneral {
    img: string,
    title: string
}


export const GeneralCard = ({img, title}:TypeGeneral) => {
  const [ visible, setVisible ] = React.useState(false);

  return (
    <div>
      
      <Viewer
        visible={visible}
        className={styles.viewer}
        onClose={() => { setVisible(false); } }
        images={[{src: img, alt: ''}]}
        
      />
        <div
        onClick={() => { setVisible(true); } } 
        className={styles.container}>
            <img
             src={img} alt="" />
        </div>
        <p className={styles.title}>{title}</p>
    </div>
  )
}
