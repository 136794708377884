interface TypeGallery {
  id: number;
  img: string;
}

export const DataGalery: TypeGallery[] = [
  {
    id: 1,
    img: "/assets/gallery/1.png",
  },
  {
    id: 2,
    img: "/assets/gallery/2.png",
  },
  {
    id: 3,
    img: "/assets/gallery/3.png",
  },
  {
    id: 4,
    img: "/assets/gallery/4.png",
  },
  {
    id: 6,
    img: "/assets/gallery/6.png",
  },
  {
    id: 7,
    img: "/assets/gallery/7.png",
  },
];
