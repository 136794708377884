import { useEffect, useState } from 'react';
import styles from './StagesOfConstruction.module.scss';
import GalleryModal from '../Gallery/GalleryModal/GalleryModal';
import { getMinId } from '../../lib/getMinId';

interface Report {
  id: number;
  title: string | null;
  image: string;
  year: string;
  month_id: number;
  created_at: string;
  month: string;
}

export const StagesOfConstruction = () => {
  const [open, setOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState<number>(0); // Используем useState для управления активным слайдом
  const [selectedYear, setSelectedYear] = useState<number>(2024);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [dataReport, setDataReport] = useState<Report[]>([]);
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [availableMonths, setAvailableMonths] = useState<{ month: string, month_id: number }[]>([]);
  const [filteredData, setFilteredData] = useState<Report[]>([]);

  const fetchData = async () => {
    try {
      const response = await fetch('https://photoreport.bobo.tj/api/mkr298/all-reports');
      const jsonResponse: { data: Report[] } = await response.json();
      setDataReport(jsonResponse.data);
      extractUniqueYears(jsonResponse.data);
    } catch (error) {
      console.error('Ошибка получения данных:', error);
    }
  };

  const extractUniqueYears = (data: Report[]) => {
    const years = Array.from(new Set(data.map(item => Number(item.year))));
    setAvailableYears(years);
    setSelectedYear(years[0]);
    updateMonths(data, years[0]);
  };

  const updateMonths = (data: Report[], year: number) => {
    const uniqueMonthsMap = new Map<number, { month: string, month_id: number }>();
    data.filter(item => Number(item.year) === year).forEach(item => {
      if (!uniqueMonthsMap.has(item.month_id)) {
        uniqueMonthsMap.set(item.month_id, { month: item.month, month_id: item.month_id });
      }
    });

    const uniqueMonths = Array.from(uniqueMonthsMap.values()).sort((a, b) => a.month_id - b.month_id);
    setAvailableMonths(uniqueMonths);
    const defaultMonth = uniqueMonths.length > 0 ? uniqueMonths[0].month_id : null;
    setSelectedMonth(defaultMonth);
  };

  const filterData = (data: Report[], year: number, month: number | null) => {
    const filtered = data.filter(item => Number(item.year) === year && item.month_id === month);
    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedYear !== null && selectedMonth !== null) {
      filterData(dataReport, selectedYear, selectedMonth);
    }
  }, [selectedYear, selectedMonth, dataReport]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = parseInt(event.target.value);
    setSelectedYear(selectedYear);
    updateMonths(dataReport, selectedYear);
  };

  const setToggleFn = (el: number) => {
    setSelectedMonth(el);
  };

  const handleImageClick = (index: number) => {
    setInitialSlide(index); // Устанавливаем индекс нажатого изображения
    setOpen(true); // Открываем модальное окно
  };

  // console.log(initialSlide)
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Этапы строительства</h1>
      </div>
      <GalleryModal
        open={open}
        close={() => setOpen(false)}
        initialSlide={initialSlide} // Передаем активный слайд
        pictures={filteredData.map((stage) => ({
          id: stage.id,
          image: stage.image,
        }))}
      />
      <div className={styles.btnContainer}>
        <div className={styles.select}>
          <select onChange={handleYearChange} value={selectedYear}>
            {availableYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.select}>
          <select
            onChange={(e) => setToggleFn(Number(e.target.value))}
            value={selectedMonth || ''}
            disabled={availableMonths.length === 0}
          >
            {availableMonths.map(({ month, month_id }) => (
              <option key={month_id} value={month_id}>
                {month}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.imageContainer}>
        {filteredData.map(({ id, image }, index) => (
          <img
            key={id}
            src={image}
            alt="stages"
            onClick={() => handleImageClick(index)} // Обрабатываем клик на изображение
          />
        ))}
      </div>
    </div>
  );
};
