import React, { useState } from "react";
import style from "./Header.module.scss";
import { Link, animateScroll as scroll } from "react-scroll";

const Header: React.FC = () => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header className={style.header}>
      <div className={style.container}>
        <div className={style.logo}>
          <img src="/assets/logo/logo298.svg" alt="" />
        </div>
        <button
          onClick={toggleNav}
          className={`${style.burger} ${navOpen ? style.active : ""}`}
        >
          <img src={`/assets/logo/${navOpen ? "X" : "menu"}.svg`} alt="Меню" />
        </button>
        <nav className={`${style.navigation} ${navOpen ? style.active : ""}`}>
          <ul>
            <li>
              <Link
                activeClass="active"
                to="advantages"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Наши преимущества
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="general"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Общая планировка
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Галерея
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Контакты
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
