import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { GeneralCard } from "./GeneralCard/GeneralCard";
import { DataGeneral } from "./GeneralLoyautData";
import styles from "./GeneralLoyaut.module.scss";
import React from "react";

const GeneralLoyaut = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div className={styles.container} id="general">
      <div className={styles.title}>
        <h1>Общая планировка</h1>
      </div>
      <div className="">
        <Swiper slidesPerView="auto" spaceBetween={24}>
          {DataGeneral.map((el) => {
            return (
              <SwiperSlide key={el.id} className={styles.slide}>
                <GeneralCard {...el} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default GeneralLoyaut;
