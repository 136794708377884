interface TypeData {
  id: number;
  img: string;
  title: string;
}

export const DataGeneral: TypeData[] = [
  {
    id: 1,
    img: "/assets/general/1.png",
    title: "Блок А - 18 Этажей",
  },
  {
    id: 2,
    img: "/assets/general/2.png",
    title: "Блок Б - 20 Этажей",
  },
  {
    id: 3,
    img: "/assets/general/3.png",
    title: "Блок В - 22 Этажа",
  },
  {
    id: 4,
    img: "/assets/general/4.png",
    title: "Блок Г - 20 Этажей",
  },
];
