import styles from "./AboutComplex.module.scss";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AboutComplex = () => {
  return (
    <div className={styles.imageAbout}>
      <div>
        <img src="/assets/background/back2.png" alt="" />
      </div>
      <div className={styles.title}>
        <h1>О жилом комплексе</h1>
        <p>
          Строительная компания «Mashrab Group» реализовывает строительство
          многофункционального жилого комплекса «298 МКР».
        </p>
        <p>
          За годы работы компания заслужила репутацию профессионала и надежного
          партнера в сфере строительства и управления проектами любой сложности.
        </p>
        <p>
          Комплекс возводится с использованием новейших технологий и
          высококачественных энергосберегающих строительных материалов.
        </p>
      </div>
    </div>
  );
};

export default AboutComplex;
