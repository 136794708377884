import React from 'react';
import Header from '../Header/Header';
import ResidentalComplex from '../ResidentalComplex/ResidentalComplex';
import { HasUp } from '../HasUp/HasUp';
import AboutComplex from '../AboutComplex/AboutComplex';
import { Wrapper } from '../Wrapper/Wraper';
import OurAdvantages from '../OurAdvantages/OurAdvantages';
import GeneralLoyaut from '../GeneralLoyaut/GeneralLoyaut';
import { Apartments } from '../Apartments/Apartments';
import { Gallery } from '../Gallery/Gallery';
import { StagesOfConstruction } from '../StagesOfConstruction/StagesOfConstruction';
import { Footter } from '../Footter/Footter';
import { ByDeveloped } from '../ByDeveloped/ByDeveloped';
import { Infrastructure } from '../Infrastructure/Infrastructure';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';



function App() {
  return (
    <div className="App">
      <Header />
      <AnimationOnScroll animateIn="animate__zoomIn">
      <ResidentalComplex />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__slideInLeft">
      <HasUp />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__zoomIn">
      <AboutComplex />
      </AnimationOnScroll>
      <Wrapper>
      <AnimationOnScroll animateIn="animate__zoomIn">
        <OurAdvantages />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__zoomIn">
        <Infrastructure/>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__zoomIn">
        <GeneralLoyaut />
        </AnimationOnScroll>
        {/*<AnimationOnScroll animateIn="animate__fadeIn">*/}
        {/*<Apartments />*/}
        {/*</AnimationOnScroll>*/}
        <AnimationOnScroll animateIn="animate__fadeIn">
        <Gallery />
        </AnimationOnScroll>
        <StagesOfConstruction />
      </Wrapper>
      <Footter/>
      <ByDeveloped/>
    </div>
  );
}


export default App;
