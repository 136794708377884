import styles from './Footter.module.scss'
import { Field, Form, Formik } from 'formik'
import axios from 'axios'
import React, { useState } from 'react'
import generateLead from '../../lib/generateLead'
interface IContactsProps {
  name: string
  phone: string
}

export const Footter = () => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  });

  const TOKEN = "6295335527:AAHum6H_xgTq7i2_wVT3-hYShx0m0LvQnoY";
  const CHAT_ID = "-1001980399250";
  const URL_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(URL_API, {
        chat_id: CHAT_ID,
        parse_mode: 'html',
        text: generateLead(formData),
      });
      setFormData({ name: '', phone: '' });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <footer className={styles.footer} id="contacts">
      <div className={styles.containerFooter}>
        <div className={styles.title}>
          <h1>Готовы приобрести квартиру своей мечты?</h1>
          <p>Оставьте заявку, и мы свяжемся с вами в ближайшее время!</p>
        </div>
        <div id="input" className={styles.FormContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.Form}>
            <input
                className={styles.input}
                placeholder="Как вас зовут?"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                className={styles.input}
                placeholder="номер телефона"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <button type="submit" className={styles.button} disabled={loading}>
                {loading ? 'Загрузка . . .' : 'Отправить'}
              </button>
            </div>
          </form>
        </div>

        <div className={styles.line}></div>

        <div className={styles.contacts}>
          <div className="iconsFoot">
            <img src="/assets/logo/logo298White.svg" alt="logo298.svg" />
          </div>

          <div className={styles.linkContact}>
            <div className={styles.numberTelefon}>
              <h5 className={styles.nameContact}>Контакты</h5>
              <a href="tel: +992 444 298 298">+992 444 298 298</a>
            </div>

            <div className={styles.socialNetwork}>
              <h5 className={styles.nameContact}>Соц-сети</h5>
              <div className={styles.icons}>
                <a
                  target="_blank"
                  href="https://www.instagram.com/mashrab.group/"
                >
                  <img src="/assets/logo/instagram.svg" alt="" />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/people/%D0%96%D0%9A-298-%D0%9C%D0%9A%D0%A0/100093243285056/"
                >
                  <img src="/assets/logo/facebook.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
