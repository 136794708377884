import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { DataGalery } from "./GalleryData";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Gallery.module.scss";
import GalleryModal from "./GalleryModal/GalleryModal";
import { useState } from "react";

export const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  return (
    <div className={styles.container} id="gallery">
      <div className={styles.title}>
        <h1>Галерея</h1>
      </div>
      <GalleryModal
        open={isOpen}
        close={() => setIsOpen(false)}
        initialSlide={initialSlide}
        pictures={DataGalery.map((item) => ({ id: item.id, image: item.img }))}
      />

      <div className={styles.gallery}>
        <Swiper
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            "@0.48": {
              slidesPerView: 1.2,
            },
            "@1 .48": {
              slidesPerView: 2.1,
            },
          }}
        >
          {DataGalery.map((el, index) => {
            return (
              <SwiperSlide key={el.id}>
                <img
                  src={el.img}
                  alt="galery"
                  onClick={() => {
                    setIsOpen(true);
                    setInitialSlide(index);
                  }}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
