import classes from './gallery-modal.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { AnimatePresence, motion } from 'framer-motion'
import {IGalleryPicture} from './IGalleryPicture'

interface Props {
  open: boolean
  pictures: IGalleryPicture[]
  initialSlide?: number
  close(): void
}

function GalleryModal({open, close, pictures, initialSlide}: Props) {
  const paginationId = 'gallery-modal-pagination'
  const prevId = 'gallery-modal-prev'
  const nextId = 'gallery-modal-next'

  return (
    <AnimatePresence>
      {open && <div className={classes.modal}>
        <div onClick={close} className={classes.backdrop}/>
        <button
          className={classes.close}
          onClick={close}
          aria-label="Закрыть"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" stroke="black" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" d="M18 18L6 6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill="none" d="M18 6L6 18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <motion.div
          initial={{
            opacity: 0,
            scale: .9,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          exit={{
            opacity: 0,
            scale: .9,
          }}
          className={classes.content}
        >
          <Swiper
            className={classes.swiper}
            modules={[Pagination, Navigation]}
            navigation={{
              enabled: true,
              prevEl: `#${prevId}`,
              nextEl: `#${nextId}`,
            }}
            pagination={{
              enabled: true,
              el: `#${paginationId}`,
              clickable: true,
              bulletClass: classes.bullet,
              bulletActiveClass: classes.bulletActive,
            }}
            initialSlide={initialSlide}
          >
            {pictures.map(({id, image}) => (
              <SwiperSlide
                key={id}
                className={classes.slide}
                onClick={close}
              >
                <img
                  className={classes.img}
                  src={image}
                  alt=""
                  width={1000}
                  height={800}
                  onClick={(event) => event.stopPropagation()}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div id={paginationId} className={classes.pagination}/>
          <div className={classes.navigation}>
            <button
              id={prevId}
              className={classes.prev}
              aria-label="Пред. изображение"
            >
                <svg width="48" height="48" viewBox="0 0 32 32" stroke="black" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0001 25.3334L10.6667 16.0001L20.0001 6.66675" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button
              id={nextId}
              className={classes.next}
              aria-label="След. изображение"
            >
                <svg width="48" height="48" viewBox="0 0 32 32" stroke="black" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 6.66675L21.3333 16.0001L12 25.3334" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
          </div>
        </motion.div>
      </div>}
    </AnimatePresence>
  )
}

export default GalleryModal